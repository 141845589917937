import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProtectedRoute, Consts, StatusHelper, SessionTimeout } from '../../common';
import { fetchSubIds } from '../actions';

import { EditProfile, AffiliateApproved } from '../../registration';
import { EditPayment, ChangeBusinessType } from '../../payment';
import OptInOutSettings from './OptInOutSettings';
import SubIds from './SubIds';
import MyAccount from './MyAccount';
import Unsubscribe from './Unsubscribe';
import Accessible from './Accessible';
import AffiliateHome from './AffiliateHome';

import './Affiliate.css';

const { RegistrationStatus } = Consts;

class Affiliate extends Component {
    constructor(props) {
        super(props);
        const { affiliate } = props;
        const showWelcome =
            affiliate &&
            affiliate.regFinalStatus === RegistrationStatus.Approved &&
            !affiliate.autoApproved &&
            new Date(affiliate.createdDate) >= new Date('2018-11-28') &&
            !this.hadShowWelcome;

        this.state = {
            showWelcome,
        };
    }

    get hadShowWelcome() {
        const ls = window.localStorage;
        const keys = Object.keys(ls);
        for (let key of keys) {
            if (/FIRST_LOGIN_AFTER_APPROVED_\w+/.test(key) || /enterPaymentLater\w+/.test(key)) {
                return true;
            }
        }
        return false;
    }

    get showWelcomeKey() {
        const affiliateCode = (this.props.affiliate || {}).affiliateCode;
        return `FIRST_LOGIN_AFTER_APPROVED_${affiliateCode}`;
    }

    welcomeShowed() {
        window.localStorage[this.showWelcomeKey] = 1;
    }

    confirmWelcome = () => {
        this.welcomeShowed();
        this.setState({ showWelcome: false });
    };

    componentDidMount() {
        const { affiliate, webAccessibleStatus, fetchSubIds } = this.props;
        // Legacy affiliate, no need to show welcome when it's fully approved
        if (affiliate && affiliate.regFinalStatus === RegistrationStatus.ProfileApproved) {
            this.welcomeShowed();
        }
        if (affiliate && !webAccessibleStatus.blocked) {
            fetchSubIds();
        }
    }

    render() {
        const { match, affiliate, webAccessibleStatus, affiliateTypeChange } = this.props;
        if (!affiliate) {
            return <Redirect to="/" />;
        }

        if (this.state.showWelcome) {
            return <AffiliateApproved onOk={this.confirmWelcome} />;
        }

        return (
            <React.Fragment>
                <Switch>
                    {!webAccessibleStatus.blocked && <Route path={`${match.path}/myaccount`} component={MyAccount} />}
                    {!webAccessibleStatus.blocked && <Route path={`${match.path}/profile`} component={EditProfile} />}
                    {!webAccessibleStatus.blocked && <Route path={`${match.path}/payment`} component={EditPayment} />}
                    {!webAccessibleStatus.blocked && (
                        <ProtectedRoute
                            path={`${match.path}/change-business-type`}
                            component={ChangeBusinessType}
                            conditions={[
                                {
                                    condition: () => {
                                        const approved = [
                                            RegistrationStatus.Approved,
                                            RegistrationStatus.AutoApproved,
                                        ].includes(affiliate.regFinalStatus);
                                        const allowChangeBusinessType =
                                            affiliateTypeChange && affiliate.affiliateType === affiliateTypeChange.from;
                                        return approved && allowChangeBusinessType;
                                    },
                                    fallback: '/affiliate',
                                },
                            ]}
                        />
                    )}
                    {!webAccessibleStatus.blocked && (
                        <Route path={`${match.path}/opt-in-out-settings`} component={OptInOutSettings} />
                    )}
                    {!webAccessibleStatus.blocked && <Route path={`${match.path}/sub-ids`} component={SubIds} />}
                    {!webAccessibleStatus.blocked && <Route path={`${match.path}/ws`} component={AffiliateHome} />}
                    {!webAccessibleStatus.blocked && (
                        <Route path={`${match.path}/unsubscribe`} component={Unsubscribe} />
                    )}
                    {webAccessibleStatus.blocked && <Route path={`${match.path}/accessible`} component={Accessible} />}

                    <Redirect
                        from="*"
                        to={webAccessibleStatus.blocked ? `${match.path}/accessible` : `${match.path}/ws`}
                    />
                </Switch>

                <SessionTimeout />
            </React.Fragment>
        );
    }
}

export default withRouter(
    connect(
        (state) => ({
            affiliate: state.affiliate,
            webAccessibleStatus: state.webAccessibleStatus,
            autoApproveEnabled: state.config.autoApproveEnabled,
            affiliateTypeChange: state.config.affiliateTypeChange,
        }),
        { fetchSubIds }
    )(Affiliate)
);
