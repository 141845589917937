import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { Table } from 'antd';
import MediaQuery from 'react-responsive';


function makeDataTableRequestPayload({ columns, pagination, filters, sorter, extraFilters }) {
    let allFilters = [];
    for (let [key, value] of Object.entries(filters)) {
        const col = columns.find(x => x.key === key || x.dataIndex === key);
        const filterMultiple = col && col.filterMultiple;
        if (filterMultiple === false) {
            let nv = [];
            if (value && value[0]) {
                nv.push(value[0]);
            }
            allFilters.push({ column: key, operator: 'EQUAL', value: nv });
        } else {
            if (value && value.length) {
                allFilters.push({ column: key, operator: 'IN', value: value.map(item => item.split(',')).reduce((a, b) => a.concat(b), []) });
            }
        }
    }
    if (Array.isArray(extraFilters)) {
        extraFilters.forEach(item => {
            allFilters.push({ operator: 'EQUAL', ...item, value: [].concat(item.value) });
        });
    }
    return {
        page: {
            size: pagination.pageSize,
            pageNumber: pagination.current - 1,
        },
        filters: allFilters,
        sorts: sorter.columnKey && sorter.order ? [{ column: sorter.columnKey, isDesc: sorter.order === 'descend' }] : []
    };
}

export default class DataTable extends PureComponent {
    static propTypes = {
        rowKey: PropTypes.string.isRequired,
        columns: PropTypes.array.isRequired,
        extraFilters: PropTypes.arrayOf(PropTypes.shape({
            column: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
            operator: PropTypes.oneOf(['LOWER_THAN', 'LOWER_EQUAL', 'GREATER_THAN', 'GREATER_EQUAL', 'EQUAL', 'CONTAIN', 'IN', 'NUMBER_IN']),
        })),
        getData: PropTypes.func.isRequired,
        onLoaded: PropTypes.func,
        className: PropTypes.string
    }


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            pagination: {
                current: 1,
                pageSize: props.pageSize || 10,
                pageSizeOptions: props.pageSizeOptions || ['10', '20', '50', '100']
            },
            filters: {},
            sorter: {},
            extraFilters: props.extraFilters,
        };
    }

    loadData = (ajaxIndicator = true) => {
        this.setState({
            loading: ajaxIndicator,
        }, () => {
            const { columns } = this.props;
            const { pagination, filters, sorter, extraFilters } = this.state;
            this.props.getData(makeDataTableRequestPayload({ columns, pagination, filters, sorter, extraFilters })).then(response => {
                let { data, rCode } = response.data;
                if(this.unmount) {
                    return;
                }
                if (rCode === '0') {
                    if (this.props.onLoaded) {
                        this.props.onLoaded(data, { pagination });
                    }
                    this.setState({
                        loading: false,
                        data: data.content,
                        pagination: { ...this.state.pagination, total: data.total }
                    });

                } else {
                    console.log(`data table response rCode is ${rCode}`);
                }
            }, error => {
                console.error('Retrieve data error: ', error);
            });
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        if(pagination.pageSize !== this.state.pagination.pageSize){
            pagination.current = 1;
        }
        const { columns } = this.props;
        const { extraFilters } = this.state;
        this.setState({ pagination, filters, sorter, }, ()=>{
            if(this.props.onDataTableChange){
                this.props.onDataTableChange(makeDataTableRequestPayload({ columns, pagination, filters, sorter, extraFilters }));
            }
            this.loadData();
        });
    }

    componentDidMount() {
        this.loadData();
    }

    refresh(ajaxIndicator = true) {
        this.loadData(ajaxIndicator);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.extraFilters !== this.props.extraFilters) {
            this.setState({
                extraFilters: this.props.extraFilters,
                pagination: {...prevState.pagination, current: 1},
            }, this.loadData);
        }
    }

    static contextTypes = {
        antLocale: PropTypes.object
    };

    render() {
        const { showLoading = true, className, rowKey, columns, extraFilters, ...rest } = this.props;
        const { loading, data } = this.state;
        const { DataTable: { TotalItems } = { TotalItems: '{0}-{1} of {2} items' } } = (this.context ? this.context.antLocale : {}) || {};
        return (
            <MediaQuery minWidth={992}>
                {
                    (matches) => {
                        if (!columns.find(x => x.fixed)) {
                            if (matches) {
                                columns[0].fixed = undefined;
                            } else {
                                columns[0].fixed = 'left';
                                columns[0].width = columns[0].width || 125;
                            }
                        }

                        return (
                            <Table className={className}
                                loading={showLoading && loading}
                                rowKey={rowKey}
                                columns={columns}
                                dataSource={data}
                                scroll={matches ? {} : { x: '300%' }}
                                pagination={{
                                    ...this.state.pagination,
                                    showSizeChanger: true, //TODO configurable
                                    showQuickJumper: true,
                                    showTotal: (total, range) => TotalItems.replace(/\{(\d+)\}/g, (m, val) => ([range[0], range[0] + data.length - 1, total][val]))
                                }}
                                onChange={this.handleTableChange}
                                {...rest}
                            />
                        );
                    }
                }
            </MediaQuery>
        );
    }
}