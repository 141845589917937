export default {
    COUPANG_PARTNERS: '쿠팡 파트너스',
    LANDING_SLOGAN: '쿠팡과 함께 수익을{br}창출해보세요.',
    LANDING_WHY_AFFILIATE: '왜 쿠팡 파트너스 인가요?',
    LANDING_WHY1_TITLE: '쉬운 가입',
    LANDING_WHY2_TITLE: '다양한 광고',
    LANDING_WHY3_TITLE: '높은 수익',
    LANDING_WHY1_DESCRIPTION: '쿠팡 회원이면 누구나 쉽게 가입이 가능합니다. 성과를 확인 할 수 있는 리포트와 편리한 정산까지, 지금 바로 가입하세요!',
    LANDING_WHY2_DESCRIPTION: '쿠팡에서 판매중인 수백만 개의 상품을 광고 할 수 있습니다. 마음껏 상품을 골라보고 한 번의 클릭으로 광고를 제작해 보세요.',
    LANDING_WHY3_DESCRIPTION: '쿠팡 파트너스는 보다 높은 커미션으로 더 많은 수익을 기대 할 수 있습니다.',
    LANDING_HOME_BANNERS: '진행중인 프로모션',
    LANDING_HOW_TO_USE: '어떻게 사용하나요?',
    LANDING_HOW1_DESCRIPTION: '당신의 사이트에서 쿠팡 파트너스의 광고를 보여주세요',
    LANDING_HOW2_DESCRIPTION: '방문자가 광고를 클릭하고 쿠팡에서 구매를 합니다 ',
    LANDING_HOW3_DESCRIPTION: '방문자가 구매한 금액의 N%를 수익으로 지급합니다 ',

    MEMBER_AFFILIATE_TITLE: '아직 가입되지 않은 ID입니다.',
    MEMBER_AFFILIATE_REGISTER_TIP: '파트너스 회원가입을 진행하시겠습니까?',
    MEMBER_AFFILIATE_JOIN_NOW: '지금 가입',

    MEMBER_BLOCKED_TITLE: '약관 및 정책에 따라 가입이 제한된 계정입니다',
    MEMBER_BLOCKED_TIP: '자세한 사항은 쿠팡 파트너스에 문의 바랍니다.',

    GO_BACK: '뒤로 가기',
    
    GOTO_LOGIN_TITLE: '쿠팡 아이디를 이용해 {newLine} 가입이 가능합니다. ',
    GOTO_LOGIN_TIP: '쿠팡 로그인 화면으로 {seconds} 초후에 자동으로 이동합니다.',

    REGISTRATION_TC_V1: '[필수] 서비스 약관에 동의합니다',
    REGISTRATION_PS_V1: '[필수] 개인정보 수집 및 이용에 동의합니다',
    REGISTRATION_PRIVACY_POLICY: '파트너스 개인정보 처리방침',
    REGISTRATION_TC_PI_V1: '개인정보 수집 내용을 이해했고 동의합니다.',
    REGISTRATION_TITLE: '쿠팡 파트너스 가입',
    REGISTRATION_TC_TITLE: '이용약관 동의​',
    REGISTRATION_TC_UNDERSTAND_TC: '서비스 이용약관 내용을 이해했고 동의합니다.',
    REGISTRATION_TC_UNDERSTAND_PI: '개인정보 수집 내용을 이해했고 동의합니다.',
    REGISTRATION_TC_DETAIL_TITLE: '서비스 이용약관',
    REGISTRATION_TC_PLEASE_ACCEPT: '약관에 동의해 주시기 바랍니다',
    REGISTRATION_PROFILE_TITLE: '프로필 정보입력',
    REGISTRATION_PROFILE_TIP1: '쿠팡의 링크나 배너를 보여줄 본인의 블로그, 웹사이트 또는 모바일앱 정보를 입력해주세요.',
    REGISTRATION_PROFILE_TIP2: '1개 입력 필수, 웹사이트/모바일 선택가능.',
    REGISTRATION_PROFILE_TIP: `쿠팡의 링크나 배너를 게시할 본인의 블로그, 웹 사이트 또는 모바일 앱 정보를 <span>모두</span> 입력해주시기 바랍니다. <br />원활한 최종 승인 절차를 위하여 각 웹 사이트 및 앱 별로 게시된 파트너스의 링크와 배너를 확인할 수 있는 <a>스크린샷</a>을 함께 올려주시기 바랍니다. {image}<br /> 등록하지 않은 채널에서 광고 활동을 하면 부정 행위로 간주될 수 있으며, 이로 인해 불이익을 받으시는 일이 없으시기 바랍니다. (가입 이후에도 '계정관리' 메뉴에서 추가할 수 있습니다.)`,
    REGISTRATION_PROFILE_AF_SELECT: '사업자 유형을 선택해주세요.',
    REGISTRATION_PROFILE_AF_SELECT_TIPS: '간이 과세자의 경우, 세금 계산서 발행이 불가능하기 때문에 개인 사업자로 선택해주세요.',
    REGISTRATION_PROFILE_AF_INDIVIDUAL: '개인',
    REGISTRATION_PROFILE_AF_COMPANY: '법인/개인 사업자{br}(세금계산서 제공)',
    REGISTRATION_PROFILE_AF_OTHER_INDUSTRIES: '개인 사업자{br}(세금계산서 미제공)',
    REGISTRATION_PROFILE_AF_TOOLTIP: '개인사업자의 경우 사업자 등록증에 기재된 종목을 기준으로 선택해 주시기 바랍니다.',
    REGISTRATION_PROFILE_ENTER_ONLY_ALPHANUMERIC: '영문과 숫자로만 입력해주세요',
    REGISTRATION_PROFILE_WEBSITES: '웹사이트 목록',
    REGISTRATION_PROFILE_APPS: '모바일 앱 목록',
    REGISTRATION_PROFILE_APPS_PLACEHOLDER: '플레이 스토어 혹은 애플 앱스토어의 앱 URL을 입력해주세요',
    REGISTRATION_PROFILE_APPS_SHORT_PLACEHOLDER: '플레이 스토어 혹은 애플 앱스토어의 앱 URL',
    REGISTRATION_PROFILE_APP_URL: '앱 URL',
    REGISTRATION_PROFILE_WEBSITE_APP_URL: '웹사이트 또는 모바일 앱 선택',
    REGISTRATION_PROFILE_WEBSITE_APP_URL_PLACEHOLDER_ID: 'ID 입력',
    REGISTRATION_PROFILE_WEBSITE_APP_URL_PLACEHOLDER_ADDRESS: '주소',
    REGISTRATION_PROFILE_URL_TOOLTIP: '옵션 선택 후 ID만 입력해주시면 전체 URL이 자동으로 완성됩니다. ​\r\n원하는 값이 없을 경우 직접 URL을 입력해 주세요.​',
    REGISTRATION_PROFILE_URL_QUANTITY_TOOLTIP: '일방문자/팔로워/모바일 앱의 경우 앱 다운로드 수를 입력해주세요',
    REGISTRATION_PROFILE_URL_QUANTITY_PLACEHOLDER: '일 방문자 수',
    REGISTRATION_PROFILE_DESCRIPTION: '귀하의 웹 사이트는 어떤 성격입니까?',
    REGISTRATION_PROFILE_DESCRIPTION_PLACEHOLDER: '예) 육아정보 사이트, IT 디지털 블로그...',
    REGISTRATION_PROFILE_TOPICS: '귀하의 웹 사이트 또는 모바일 앱을 설명하는 주제는 무엇입니까?',
    REGISTRATION_PROFILE_TOPICS_PLACEHOLDER: '직접 입력',
    REGISTRATION_PROFILE_TOPICS_LIGHT: '주제',
    REGISTRATION_PROFILE_SIGNUP: '가입완료',
    REGISTRATION_PROFILE_MSG_INVALID_SITE: '유효한 URL을 입력하십시오.',
    REGISTRATION_PROFILE_MSG_INVALID_APP: '유효하지 않은 앱 URL입니다.',
    REGISTRATION_PROFILE_MSG_ENTER_WEBSITE_OR_APP: '최소 1개의 웹사이트 혹은 모바일앱을 입력해주세요',
    REGISTRATION_PROFILE_MSG_SELECT_AT_LEAST_ONE_TOPIC: '적어도 1개의 주제를 선택 혹은 직접 입력해 주세요',
    REGISTRATION_PROFILE_MSG_MODIFY_SITE_APP: '해당 사이트가 ‘쿠팡 파트너스 이용약관 및 운영정책’에 위배되지 않음을 확인합니다',
    REGISTRATION_PROFILE_AGREE_ALL: '에 모두 동의합니다.',
    REGISTRATION_PROFILE_OWNER: '소유주',
    REGISTRATION_PROFILE_OWNER_TOOLTIP: '해당 페이지의 소유주입니까?',
    REGISTRATION_SUMMARY_WELCOME: '{name}님, 환영합니다!',
    REGISTRATION_SUMMARY_THANKS: '쿠팡 파트너스에 가입해주셔서 감사합니다!',
    REGISTRATION_SUMMARY_3_DAYS: '승인까지 영업일 기준 3일정도 소요됩니다.',
    REGISTRATION_SUMMARY_NOTICE: '승인이 완료되면 가입하신 이메일{newLine}{email}로 연락드리겠습니다.',
    REGISTRATION_AFFILIATE_IN_PROGRESS_TITLE: '승인 요청을 검토중입니다',
    REGISTRATION_AFFILIATE_IN_PROGRESS_DETAIL: '조금만 더 기다려주세요.{newLine}감사합니다.',
    REGISTRATION_AFFILIATE_REJECTED_TITLE: '최종 승인 반려 안내',
    REGISTRATION_AFFILIATE_REJECTED_REFUSAL_FOLLOWS: '본 계정의 최종 승인이 반려 되었습니다. 아래 반려사유를 확인 및 수정 후 재신청 바랍니다. ',
    REGISTRATION_AFFILIATE_REJECTED_DETAIL_P1: '입력하신 정보는 모두 삭제됩니다. ',
    REGISTRATION_AFFILIATE_REJECTED_DETAIL_P2: '자세한 사항은 쿠팡 파트너스 이메일을 통해 문의바랍니다.',
    ACCEPT_TC_TO_CONTINUE: '수집에 동의해야 서비스를 이용하실 수 있습니다.',

    REGISTRATION_AFFILIATE_REAPPLY: '최종 승인 재신청',
    REGISTRATION_AFFILIATE_REAPPLY_COMPLETED: '최종승인 재검토 요청이 완료되었습니다!',
    REGISTRATION_AFFILIATE_REAPPLY_COMPLETED_NOTICE: '검토 후 승인이 완료되면 가입하신 이메일{newLine}{email}로 연락 드리겠습니다.',
    REGISTRATION_AFFILIATE_REAPPLY_UNDER_REVIEW: '최종승인 재검토 요청이 완료되어 검토 중입니다. 승인이 완료되면 가입하신 이메일 {email} 로 연락 드리겠습니다',
    REGISTRATION_FOREIGNER_USER_CANNOT_JOIN: '외국인은 파트너스 가입이 불가능합니다.',
    REGISTRATION_UNDERAGE_USER_CANNOT_JOIN: '미성년자는 파트너스 가입이 불가능합니다.',
    REGISTRATION_IDENTITY_CONFIRM: '본인의 인증정보가 맞는지 확인합니다. 본인이 아닌 경우 추후 수익금 지급이 제한 될 수 있습니다.',
    REGISTRATION_PLEASE_CONFIRM_IT_IS_YOUR_IDENTITY: '본인의 인증정보가 맞는지 확인합니다.',
    REGISTRATION_WITHOUT_REAL_NAME_AUTHENTICATION: '본인인증 내역이 없습니다. {link}',
    REGISTRATION_REAL_NAME_AUTHENTICATION_TIPS_1: '회원님의 쿠팡 본인인증을 통해 확인된 내용입니다.',
    REGISTRATION_REAL_NAME_AUTHENTICATION_TIPS_2: '본인 정보가 맞는지 확인하고 체크해 주세요.',
    REGISTRATION_GOTO_REAL_NAME_AUTHENTICATION: '인증하러 가기',
    REGISTRATION_RRN: '고유식별정보 수집 및 이용',
    REGISTRATION_BANK: '개인정보 수집 및 이용',
    REGISTRATION_MUST_AGREE: '필수 동의 항목',
    REGISTRATION_DETAIL_FOR_PRIVACY: '더 자세한 내용에 대해서는 {link}을 참고하시기 바랍니다. ',
    REGISTRATION_PII_COLLECTION: '대금 정산 및 지급을 위한 정보 수집',
    REGISTRATION_PII_COLLECTION_CONTENT: '쿠팡(주)가 계좌 인증 및 정산 대금 지급을 위한 목적으로 개인정보(계좌정보 포함) 및 고유식별정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.',
    REGISTRATION_PII_COLLECTION_LATER_TIPS: '결제정보 입력란은 대금 지급 조건을 갖추신 회원에 한해 매월 15일에 활성화됩니다. {br}대금지급조건은 최종 승인이 완료되었으며, 직전 월까지의 누계 수익이 1만원 이상인 경우에 해당합니다.',
    REGISTRATION_CHANNEL_TIPS: '등록하지 않은 채널에서 광고 활동을 하면 부정행위로 간주될 수 있으며, 이로 인해 불이익을 받으시는 일이 없으시길 바랍니다. 가입 후에도 마이페이지 안에 계정관리에서 블로그와 사이트 주소, 앱 목록을 추가할 수 있습니다.',
    REGISTRATION_COMMISSION_CAP_TIPS: '쿠팡 파트너스 활동에 따른 수입 지급의 최대 금액은 1개월 기준 3,000만원이며, 이를 초과하여 발생한 수입은 이월되거나 지급되지 않습니다.',

    MARKETING_PI_COLLECTION: '[선택] 마케팅 목적의 개인정보 수집 및 이용 동의',
    MARKETING_EMAIL: '[선택] 광고성 정보 수신 동의 (이메일, SMS)',
    MARKETING_PI_COLLECTION_OPT_IN: '마케팅 및 이벤트 목적의 개인정보 수집 및 동의 완료',
    MARKETING_PI_COLLECTION_OPT_OUT: '마케팅 및 이벤트 목적의 개인정보 수집 및 철회 완료',
    MARKETING_EMAIL_OPT_IN: '광고성 정보 수신 동의 완료 (이메일)',
    MARKETING_EMAIL_OPT_OUT: '광고성 정보 수신 철회 완료 (이메일)',

    REFERRER_LABEL: '추천인 코드',
    REFERRER_TOOLTIP: '쿠팡 파트너스를 추천해 주신분의 ID를 입력해주세요. ​ID는 AF+7자리 숫자 형태입니다 (예:AF1234567)​',
    REFERRER_VAL_MSG: 'ID는 AF+7자리 숫자 형태입니다 (예:AF1234567)​',
    REFERRER_PLACEHOLDER: 'AF + 7자리 숫자로 구성되어 있습니다.',
    REFERRER_NOT_FOUND: '존재하지 않는 ID입니다.',
    REFERRER_NOT_FINALLY_APPROVED: '최종 승인되지 않은 회원의 ID는 추가할 수 없습니다.',
    REFERRER_DEACTIVATION_IN_PROGRESS: '탈퇴 절차가 진행중인 회원의 ID는 추가할 수 없습니다.',
    REFERRER_DEACTIVATION_COMPLETE: '탈퇴 절차가 완료된 회원의 ID는 추가할 수 없습니다.',
    REFERRER_FRAUD: '부정행위로 조사중인 회원의 ID는 추가할 수 없습니다.',

    TRACKING_CODE: '회원 ID',

    PAYMENT_ALERT_GREETING: '{name}님',
    PAYMENT_ALERT_TITLE_APPROVED: '가입이 승인되었습니다!',
    PAYMENT_ALERT_TITLE_AUTO_APPROVED: '가입이 완료되었습니다!',
    PAYMENT_ALERT_AFFILIATE_ID_IS: '{name}님의 쿠팡 파트너스 ID는 {affiliateCode} 입니다.',
    PAYMENT_ALERT_MESSAGE: '결제정보를 입력하셔야 수익금이 지급됩니다.{newLine}지금 입력 하시겠습니까?',
    PAYMENT_ALERT_LATER: '나중에',
    PAYMENT_ALERT_NOW: '결제정보 입력',
    APPROVED_AFFILIATE_ID_IS: '{name}님의 쿠팡 파트너스 ID는 {affiliateCode} 입니다.',
    APPROVED_CREATE_A_LINK_NOW: '지금 바로 링크를 만들어보세요.',
    PAYMENT_TITLE: '결제정보 입력',
    PAYMENT_SELECT_TYPE_TITLE: '사업 유형을 선택해주세요',
    PAYMENT_SELECT_TYPE_INDIVIDUAL: '개인',
    PAYMENT_SELECT_TYPE_INDIVIDUAL_OTHERS: '개인사업자 (세금계산서 미제공)',
    PAYMENT_SELECT_TYPE_CORPORATION: '법인 / 개인사업자 (세금계산서 제공)',
    PAYMENT_SELECT_TYPE_PLEASE_SELECT_A_TYPE: '업체 유형을 선택하십시오',
    PAYMENT_SECTION_ACQUISITION_OF_PI: '개인정보 수집동의',
    PAYMENT_SECTION_PAYER_INFORMATION: '결제자 정보를 입력해 주세요.',
    PAYMENT_SECTION_COMPANY_INFORMATION: '사업자 정보',
    PAYMENT_SECTION_CONTACT: '연락처를 입력해 주세요.',
    PAYMENT_SECTION_REFERRER: '추천인 코드가 있으면 입력해 주세요.',
    PAYMENT_SECTION_ACCOUNT_INFORMATION: '계좌 정보를 입력해 주세요.',
    PAYMENT_RRN: '주민등록번호',
    PAYMENT_RRN_TIP1:'<span class="color-accent ft-bold-500">대금 정산</span>을 위하여 <span class="color-accent ft-bold-500">주민등록번호</span>를 소득세법 제145조에 따라 수집이용하며, 이 법에 따라 <span class="color-accent ft-bold-500">5년 동안 보관</span>합니다.',
    PAYMENT_RRN_TIP2: '가입시 확인된 본인 인증 정보와 동일한 주민등록 정보가 제공되어야 합니다.',
    PAYMENT_BRN: '사업자등록번호',
    PAYMENT_PHONE_NUMBER: '전화번호',
    PAYMENT_EMAIL: '이메일',
    PAYMENT_EMAIL_PLACEHOLDER: '예) email@domain.com',
    PAYMENT_OMIT_MINUS_SYMBOL: "'-'를 생략하고 입력해주세요",
    PAYMENT_BANK: '은행',
    PAYMENT_SELECT_A_BANK: '은행을 선택해주세요',
    PAYMENT_ACCOUNT_NUMBER: '계좌번호',
    PAYMENT_ACCOUNT_HOLDER: '계좌 소유자',
    PAYMENT_COMPANY_REPRESENTATIVE_NAME: '대표자명',
    PAYMENT_COMPANY_NAME: '회사명',
    PAYMENT_COMPANY_NAME_TIP: '사업자 등록증 상의 상호명 혹은 법인명을 입력해주세요.',
    PAYMENT_ADDRESS: '사업장 주소',
    PAYMENT_ADDRESS_TIP: '개인의 주소가 아닌 사업장의 주소를 기재해주세요',
    PAYMENT_COMPANY_BUSINESS_SECTOR: '비지니스 업종',
    PAYMENT_COMPANY_BUSINESS_TYPE: '업태',
    PAYMENT_COMPANY_SUB_BUSINESS_TYPE: '종목',
    PAYMENT_CONSENT_DETAIL_TITLE: '개인정보 수집이용 동의 및 권리 안내',
    PAYMENT_UPLOAD_DOCUMENT: '정산 서류를 업로드해 주세요.',
    PAYMENT_MSG_BRN_IS_10_DIGITALS: '사업자 등록번호는 10자리 숫자를 입력해 주셔야 합니다',
    PAYMENT_MSG_CHECK_BRN_FIRST: '사업자 등록번호 인증 버튼을 눌러주세요',
    PAYMENT_MSG_INVALID_BRN: '사업자 등록번호가 맞지 않습니다. 다시 확인해 주세요',
    PAYMENT_MSG_ENTER_NAME_FIRST_FOR_RRN_VERIFICATION: '주민등록번호를 확인하기 전에 이름을 입력하십시오.',
    PAYMENT_MSG_RRN_IS_13_DIGITALS: '주민등록번호는 13자리 숫자를 입력해주셔야 합니다',
    PAYMENT_MSG_RRN_ILLEGAL: '이름과 주민번호가 일치하지 않습니다',
    PAYMENT_MSG_CHECK_RRN_FIRST: '주민등록번호 인증 버튼을 눌러주세요',
    PAYMENT_MSG_INVALID_BANK_ACCOUNT: '계좌번호가 올바르지 않습니다. 다시 확인 후 입력해주세요.',
    PAYMENT_MSG_VERIFY_BANK_ACCOUNT_FIRST: '계좌번호 인증버튼을 눌러주세요',
    PAYMENT_MSG_SELECT_BANK_FIRST: '은행명 먼저 선택해 주세요',

    HEADER_HOME: '홈',
    HEADER_PRODUCT_LINK: '링크 생성',
    HEADER_REPORT: '리포트',
    HEADER_PERFORMANCE_REPORT: '실적 리포트',
    HEADER_REPORT_TREND: '기간별 리포트',
    HEADER_REPORT_TREND_SUB_TITLE: '전일자 데이터가 오후 4시 전후로 반영됩니다.',
    HEADER_REPORT_DYNAMIC_WIDGET: '다이나믹 배너 리포트',
    HEADER_REPORT_PERFORMANCE_INSIGHT: '실적 상세 리포트',
    HEADER_EARNING_REPORT: '수익 리포트',
    HEADER_REPORT_EARNING: '수익 요약',
    HEADER_REPORT_SETTLEMENT: '정산 리포트',
    HEADER_REPORT_SETTLEMENT_DETAILS: '정산 상세',
    HEADER_REPORT_PAYOUT: '지급 리포트',
    HEADER_REPORT_PAYOUT_DETAILS: '지급 상세',
    HEADER_REPORT_DOWNLOAD_REPORT: '리포트 다운로드',
    HEADER_REPORT_REFERRAL: '추천인 리포트',
    HEADER_EDIT_PROFILE: '계정관리',
    HEADER_ENTER_PAYMENT: '결제정보 입력',
    HEADER_EDIT_PAYMENT: '결제정보 변경',
    HEADER_SUB_ID: '채널 아이디 관리',
    OPT_IN_OUT_SETTINGS: '알림 수신 설정',
    HEADER_UNSUBSCRIBE: '가입탈퇴',
    HEADER_PL_LINK: '상품 링크',
    HEADER_PL_BANNER: '카테고리 배너',
    VIDEO_BANNER: '비디오 배너',
    VIDEO_BANNER_GENERATION: '비디오 배너 생성',
    HEADER_PL_SEARCH_BAR: '검색 위젯',
    HEADER_PL_DYNAMIC_BANNER: '다이나믹 배너​',
    HEADER_PL_EVENT_LIST: '이벤트/프로모션 링크',
    EVENT_LIST_TITLE: '이벤트/프로모션 목록',
    EVENT_TITLE: '이벤트/프로모션',
    EVENT_PERIOD: '행사기간',
    HEADER_SETTLEMENT: '정산',
    HEADER_SETTLEMENT_UPLOAD: '정산서류 업로드',
    HEADER_SETTLEMENT_TAXINVOICE: '세금계산서 조회',
    HEADER_TOOLS: '추가 기능',
    HEADER_TOOLS_OPEN_API: '파트너스 API',
    HEADER_TOOLS_CHROME_EXTENSION: '크롬 확장 프로그램',
    HEADER_HELP: '도움말',
    HEADER_ANNOUNCEMENTS: '공지사항',
    HEADER_TP: '약관 및 정책',
    HEADER_HELP_TERMS: '이용약관',
    HEADER_HELP_OPERATING_POLICY: '운영정책',
    HEADER_HELP_PRIVACY_POLICY: '개인정보 처리방침',
    HEADER_HELP_USAGE_GUIDE: '이용 가이드',
    HEADER_HELP_PARTNERS_GUIDE: '파트너스 가이드',
    HEADER_HELP_USAGE_GUIDE_TAG: '다이나믹 배너',
    HEADER_HELP_OPEN_API: '파트너스 API',
    HEADER_HELP_OPEN_API_GUIDE: '가이드',
    HEADER_HELP_OPEN_API_DOC: '문서',
    HEADER_HELP_FAQ: '자주묻는 질문',
    HEADER_HELP_VOC: '이용 문의',
    HEADER_HELP_VOC_CREATE: '문의하기',
    HEADER_HELP_VOC_CREATE_SUBTITLE: '쿠팡 파트너스를 이용하면서 느끼신 불편사항이나 바라는 점을 알려주세요.',
    HEADER_HELP_VOC_HISTORY: '문의 내역',
    HEADER_HELP_VOC_HISTORY_SUBTITLE: '이전 문의 내역을 확인할 수 있습니다.',
    HEADER_HELP_VOC_HISTORY_MY_VOC: '문의 내역',
    VOC_CATEGORY_PLACEHOLDER: '문의 유형을 선택하세요',
    VOC_CATEGORY_VALIDATION_MESSAGE: '문의 유형을 선택하세요',
    VOC_TITLE_PLACEHOLDER: '문의 제목',
    VOC_TITLE_VALIDATION_MESSAGE: '제목을 입력하세요',
    VOC_CONTENT_PLACEHOLDER: '고객님의 의견을 입력해주세요',
    VOC_CONTENT_VALIDATION_MESSAGE: '고객님의 의견을 입력하세요',
    VOC_CREATE_ONE: '문의하기',

    MY_ACCOUNT_PROFILE: '내 정보관리',
    MY_ACCOUNT_PROFILE_SUBTITLE: '웹사이트,  앱, 사이트 성격, 키워드 변경',
    MY_ACCOUNT_PAYMENT: '결제정보 변경',
    MY_ACCOUNT_PAYMENT_SUBTITLE: '연락처, 계좌번호 변경',
    MY_ACCOUNT_UNSUBSCRIBE: '회원 탈퇴',
    MY_ACCOUNT_UNSUBSCRIBE_SUBTITLE: '안내사항 및 탈퇴 진행',
    MY_ACCOUNT_REACTIVATE: '재가입',
    MY_ACCOUNT_REACTIVATE_SUBTITLE: '재가입 신청은 탈퇴 후 31일 이후부터 가능합니다',
    MY_ACCOUNT_SUB_ID_MANAGEMENT: '채널 아이디 관리',
    MY_ACCOUNT_SUB_ID_MANAGEMENT_SUBTITLE: '채널 아이디 생성 및 삭제',

    EDIT_PROFILE_TITLE: '내 정보 관리',
    EDIT_PAYMENT_TITLE: '결제정보 변경',
    CHANGE_AFFILIATE_TYPE_TITLE: '사업자 유형 변경',
    CHANGE_AFFILIATE_TYPE_INDIVIDUAL_TO_OTHERS: '개인 -> 개인 사업자',
    CHANGE_AFFILIATE_TYPE_OTHERS_TO_INDIVIDUAL: '개인 사업자 -> 개인',
    CHANGE_AFFILIATE_TYPE_ALERT: '<a href="/#affiliate/change-business-type">여기</a>를 클릭해서 사업자 유형을 기타개인사업자로 변경 할 수 있습니다.',

    CIM_ALERT_TITLE: '사업자 정보와 계좌 변경은 아래와 같은 절차를 통해 수정 가능합니다​',
    CIM_ALERT_STEP1: '변경된 사업자 정보가 반영된 사업자 등록증 이미지 혹은 통장 사본 이미지를 파트너스로 보내주세요. (도움말 > 이용문의 > 문의하기) 담당',
    CIM_ALERT_STEP2: '담당자의 검토가 완료되면 해당 정보가 변경됩니다.',

    AFFILIATE_HOME: '홈',
    PRODUCT_LINK: '상품 링크',
    PRODUCT_LINK_STEP1_SLOGAN: '마음에 드는 상품을 찾아보세요.',
    PRODUCT_LINK_STEP2_SLOGAN: '상품을 광고할 링크 혹은 배너를 생성할수 있습니다.{createLinkButton} 을 클릭하여 생성해 보세요.',
    PRODUCT_LINK_STEP2_SUB_SLOGAN: '상품 페이지로 연결되는 간단한 단축 URL 혹은 상품의 이미지와 함께 배너 형태의 HTML 코드가 제공됩니다.',
    PRODUCT_LINK_STEP1: '상품 탐색 ',
    PRODUCT_LINK_STEP2: '마음에 드는 상품 선택 ',
    PRODUCT_LINK_STEP3: 'URL 혹은 배너 만들기 ',
    PRODUCT_LINK_BEST_PRODUCTS: '베스트 상품',
    PRODUCT_LINK_GOTO: '상품정보',
    PRODUCT_LINK_GENERATION: '링크 생성',
    PRODUCT_LINK_SLOGAN: '단축 URL 혹은 HTML 코드를 복사하여  블로그 혹은 SNS, 웹사이트에 붙여주세요',
    PRODUCT_LINK_SEARCH_PLACEHOLDER: '찾고 싶은 상품을 검색해보세요!',
    PRODUCT_LINK_NO_RESULT: '검색결과가 없습니다.',
    LINK_GENERATION_SHORTEN_URL: '단축 URL',
    LINK_GENERATION_IMAGE_TEXT: '이미지 + 텍스트',
    LINK_GENERATION_PREVIEW: '미리보기',
    LINK_GENERATION_BORDERLINE: '보더라인',
    LINK_GENERATION_COPY_URL: 'URL 복사',
    LINK_GENERATION_COPY_HTML: 'HTML 복사',
    LINK_GENERATION_BORDER: '보더라인',
    LINK_GENERATION_CODE_TIP: 'iframe 이 적용되지 않는 곳에는 블로그용 태그를 이용하세요',
    LINK_GENERATION_CODE_TYPE_IFRAME: '일반태그',
    LINK_GENERATION_CODE_TYPE_IMAGE: '블로그용 태그',
    BEST_ROCKET_PRODUCTS: '로켓배송 베스트',
    TOP_PRODUCTS_GOLDBOX: '골드박스',
    TOP_PRODUCTS_FASHION: '패션',
    TOP_PRODUCTS_ROCKET_FRESH: '로켓프레시',
    TOP_PRODUCTS_RETAIL_CLEARANCE: 'Retail Clearance',
    TOP_PRODUCTS_MOST_CONVERSION: '전환율이 좋은 상품',
    TOP_PRODUCTS_MOST_DISCOUNTED: '할인율이 높은 상품',
    TOP_PRODUCTS_MOST_SOLD: '최근 많이 팔린 상품',
    PRODUCT_SEARCH_SHARE_SEARCH_RESULTS: '‘{keyword}’에 대한 검색 결과를 공유하시겠습니까?',

    LINK_TO_ANY_PAGE: '간편 링크 만들기',
    LINK_TO_ANY_PAGE_CREATE_LINK: '링크 생성​',
    LINK_TO_ANY_PAGE_RECOMMENDED_LINKS: '추천 링크',
    LINK_TO_ANY_PAGE_INVALID_URL: '입력 한 URL은 유효한 coupang URL이 아닙니다.',
    LINK_TO_ANY_PAGE_NOT_SUPPORTED_URL: '죄송합니다. 이 URL은 지원하지 않는 형태입니다. 제품페이지, 검색결과, 기획전 페이지만 파트너스 링크로 생성 가능합니다.​',
    LINK_TO_ANY_PAGE_NOT_SUPPORTED_PRODUCT: '해당 상품은 법적으로 또는 판매자의 요청에 따라 파트너스 링크 생성이 제한된 상품입니다.',

    CODE_PREVIEW: '미리보기',

    PRODUCT_BANNER: '카테고리 배너',
    PRODUCT_BANNER_SUBTITLE: '쿠팡 카테고리에 링크되는 배너가 생성됩니다. ',
    PRODUCT_BANNER_GENERATION: '배너 생성',
    PRODUCT_BANNER_SELECT_CATEGORY: '카테고리 선택',

    PRESS_META_C_TO_COPY: '링크 복사를 위해 #{key},Enter를 눌러주세요',

    CATEGORY_ROCKET_DELIVERY: '로켓배송',
    CATEGORY_COUPANG: '쿠팡​',
    CATEGORY_ROCKET_WOW: '로켓와우',
    CATEGORY_SNS: '정기배송',
    CATEGORY_JIKGU: '로켓직구',
    CATEGORY_WOMEN_FASHION: '패션의류-여성',
    CATEGORY_MEN_FASHION: '패션의류-남성',
    CATEGORY_BEAUTY: '뷰티',
    CATEGORY_BABY: '출산유아동',
    CATEGORY_FOOD: '식품',
    CATEGORY_KITCHEN: '주방용품',
    CATEGORY_LIVING: '생활용품',
    CATEGORY_HOME_INTERIOR: '홈인테리어',
    CATEGORY_ELECTRONIC: '가전디지털',
    CATEGORY_SPORTS: '스포츠/레저',
    CATEGORY_CAR: '자동차용품',
    CATEGORY_BOOKS_CD_DVD: '도서/음반/DVD',
    CATEGORY_TOY: '완구/취미',
    CATEGORY_OFFICE: '문구/오피스',
    CATEGORY_PET: '반려동물용품',
    CATEGORY_HEALTH: '헬스/건강용품',
    CATEGORY_OVERSEA_TRIP: '해외여행',
    CATEGORY_DOMESTIC_TRIP: '국내여행',
    CATEGORY_TICKET: '공연/전시/체험',

    UPLOAD_A_FILE: '파일 업로드',
    UPLOAD_BUSINESS_DOC: '사업자 등록증 사본',
    UPLOAD_BANK_BOOK: '통장사본',
    UPLOAD_DOCUMENTS_TIPS: '업로드 하는 파일은 5MB 이하의 jpg, jpeg, png 형식이어야 합니다. 다른 형식의 파일 업로드시 오류가 발생 할 수 있습니다.',

    DASHBOARD_SEARCH_PRODUCTS: '상품검색',
    DASHBOARD_DAILY_TREND: '일별 실적',
    DASHBOARD_AGGREGATE_MONTH: '이번 달 집계',
    DASHBOARD_VIEW_FULL_REPORT: '전체 보고서 보기',
    DASHBOARD_AGG_CLICK: '클릭',
    DASHBOARD_AGG_PURCHASE_AMOUNT: '구매액',
    DASHBOARD_AGG_REVENUE: '수익',
    EARNING_NOTIFICATION: `이번 달 수익금 한도는 {commissionCap} 입니다.{br}이번 달 잔여 수익금 한도는 {remainingCap} 입니다.{br}현재 수익금은 수익금 한도의 {estimatedCapSpendingRate} 입니다.{br}일 평균 수익금 소진액은 {avgDailyCommission} 입니다.{br}`,

    RP_DAILY_TREND: '일별 실적',
    RP_MONTHLY_TREND: '월별 실적',
    RP_LAST_UPDATE: '최근 업데이트',
    RP_IMPRESSION_COUNT: '노출',
    RP_CLICK_COUNT: '클릭',
    RP_ORDER_COUNT: '구매 건수',
    RP_ORDER_COUNT_2: '구매',
    RP_ORDER_PAYMENT_AMT: '구매 금액',
    RP_CANCEL_COUNT: '취소',
    RP_CANCEL_AMT: '취소 금액',
    RP_REFERRAL_COUNT: '총 추천인 수',
    RP_COMMISSION: '수익',
    RP_COMMISSION_2: '수익금',
    RP_FINAL_COMMISSION: '최종 수익금',
    RP_CTR: '클릭 전환율',
    RP_CONVERSION: '전환율',
    RP_E_CPM: 'eCPM',
    RP_GMV: '합산 금액',
    RP_QUANTITY: '구매수량',
    RP_ACTUAL_PAYMENT_AMT: '구매액',
    RP_ACTUAL_PAYMENT_AMT_2: '합산 금액',
    RP_ACCUMULATED_PAYOUT_AMT: '총지급 금액',
    RP_COMMISSION_CAP: '수익금 한도',
    RP_MANUAL_BONUS: '추가 조정 수익',
    RP_REVENUE_UNIT: '단위 : 만원',
    RP_ALL_TIME_ACCUMULATED_PAYOUT_AMT: '전체 누적 지급 금액',
    RP_EARNING_MONTH: '{month}월 수익 집계',
    RP_EARNING_SUMMARY_TIPS: '* 최종 정산금액은 매월 25일에 확정되며, 주문취소 등으로 인해 변경될수 있습니다.',
    RP_VIEW_FULL_REPORT: '전체 보고서 보기',
    RP_PREVIOUS_30_DAYS: '이전 30일',
    RP_NEXT_30_DAYS: '다음 30 일',
    RP_PRODUCT: '상품',
    RP_LINK_TYPE: '링크 형태',
    RP_LINK_MODULE: '링크 유형',
    RP_TRACE_ID: '링크 ID',
    RP_SUB_ID: '채널 ID',
    RP_PLATFORM: '링크 플랫폼',
    RP_PAGE_TYPE: '페이지 유형',
    RP_PAGE_KEY: '페이지 키',
    RP_DOWNLOAD_POPUP_CONTENT: '리포트 파일이 생성 중입니다(작업 ID는 {jobId}입니다). 지금 다운로드 페이지를 확인하시겠습니까?<br />(다운로드 항목은 홈 > 리포트 > 리포트 다운로드 페이지에서 언제든 확인 할 수 있습니다.)',
    RP_REFERRAL_PERIOD: '보너스 적용 기간',

    REPORT_REFERRAL_SUB_TITLE: '나를 추천인으로 등록한 회원의 실적 정보입니다',

    SETTLEMENT_SUB_TITLE: '실적 발생일 기준 익월 25일에 업데이트 됩니다',
    SETTLEMENT_COL_MONTH: '정산 월',
    SETTLEMENT_COL_PURCHASE: '구매 건수',
    SETTLEMENT_COL_PURCHASE_AMOUNT: '구매 금액',
    SETTLEMENT_COL_CANCEL: '취소 건수',
    SETTLEMENT_COL_CANCEL_AMOUNT: '취소 금액',
    SETTLEMENT_COL_TOTAL_AMOUNT: '합산 금액',
    SETTLEMENT_COL_REVENUE: '수익 금액',
    SETTLEMENT_FINAL_REVENUE: '최종 수익 금액',
    SETTLEMENT_COL_SETTLEMENT_STATUS: '정산 상태',
    SETTLEMENT_STATUS_TIPS: '상태 값을 클릭하여 상세 정보를 확인 할 수 있습니다.',
    SETTLEMENT_COL_WITHHOLDING_TAX: '원천세',
    SETTLEMENT_COL_VAT: '부가가치세',
    SETTLEMENT_COL_REAL_PAYMENT_AMT: '실지급액',
    SETTLEMENT_COL_PAYMENT_DATE: '지급일',
    SETTLEMENT_COL_HAS_PAID: '지급여부',
    SETTLEMENT_VIEW_DETAILS: 'Details',

    SETTLEMENT_DETAILS_COL_MONTH: '정산 월',
    SETTLEMENT_DETAILS_COL_BLOCKED: '정산 보류',
    SETTLEMENT_DETAILS_COL_TYPE: '타입',
    SETTLEMENT_DETAILS_COL_TRACKING_CODE: '어필리에이트 ID',
    SETTLEMENT_DETAILS_COL_SUBID: '채널 아이디',
    SETTLEMENT_DETAILS_COL_ORDER: '구매 건수',
    SETTLEMENT_DETAILS_COL_CANCEL: '취소 건수',
    SETTLEMENT_DETAILS_COL_PURCHASE_AMOUNT: '구매 금액',
    SETTLEMENT_DETAILS_COL_CANCEL_AMOUNT: '취소 금액',
    SETTLEMENT_DETAILS_COL_COMMISSION: '수익',
    SETTLEMENT_DETAILS_COL_PAYOUT_MONTH: '지급 월',
    SETTLEMENT_DETAILS_COL_PAYOUT_DATE: '지급일자',

    COMMISSION_TYPE_COMMISSION: '수익금',
    COMMISSION_TYPE_BONUS_REFERRER: '추천 가입자 수익',
    COMMISSION_TYPE_BONUS_REFERRAL: '추천인 가입 수익(본인)',
    COMMISSION_TYPE_BONUS_GMV_GROWTH: '매출 성장 추가 수익',
    COMMISSION_TYPE_MANUAL: '추가 조정 수익',
    COMMISSION_TYPE_ADMIN_MANUAL_BONUS: '추가 조정 수익',
    COMMISSION_TYPE_COMMISSION_CAP_DEDUCTION: '수익금 한도 초과분',

    PAYOUT_STATUS_CARRY_OVER: '이월',

    TAX_INVOICE_SUB_TITLE: '실제 지급일자와 내역을 확인합니다',
    TAX_INVOICE_COL_MONTH: '정산 월',
    TAX_INVOICE_COL_BILL_NUMBER: '계산서번호',
    TAX_INVOICE_COL_AFFILIATE_CODE: '어필리에이트 ID',
    TAX_INVOICE_COL_ISSUE_DATE: '작성일자',
    TAX_INVOICE_COL_INVOICE_SEQ: 'Invoice Sequence',
    TAX_INVOICE_COL_VIEW_INVOICE: '세금계산서 보기',
    TAX_INVOICE_COL_PAYMENT_DATE: '지급일자',
    TAX_INVOICE_COL_TOTAL_AMOUNT: '공급가액',
    TAX_INVOICE_COL_VAT: 'VAT',
    TAX_INVOICE_COL_AMOUNT_TO_PAY: '지급예정금액',
    TAX_INVOICE_COL_STATUS: '세금계산서 상태',
    TAX_INVOICE_PENDING_INVOICE_MSG: '승인하지 않은 세금계산서가 있습니다. 기한내 승인되어야 수익이 지급됩니다.',

    UNSUBSCRIBE_TITLE: '회원탈퇴',
    UNSUBSCRIBE_NOTICE: '안내 사항',
    UNSUBSCRIBE_NOTICE_MSG_1: '탈퇴 신청 후 정산 관련 기능을 제외한 파트너스 기능은 사용 할 수 없습니다.',
    UNSUBSCRIBE_NOTICE_MSG_2: '탈퇴 신청일 기준 잔여수익금이 있는 경우 지급이 완료된 후 탈퇴 절차가 완료됩니다. <br /> (추천인 또는 추천가입자인 경우 다음 정산일을 기준으로 잔여수익금을 확인합니다.)',
    UNSUBSCRIBE_NOTICE_MSG_3: '탈퇴 신청일 이후 발생하는 수익은 지급받을 수 없습니다.',
    UNSUBSCRIBE_NOTICE_MSG_4: '탈퇴 절차가 완료되면 기존 정보 확인은 불가능합니다.',
    UNSUBSCRIBE_NOTICE_MSG_5: '탈퇴 절차가 완료된 후 재가입이 가능합니다.',
    UNSUBSCRIBE_NOTICE_MSG_6: '탈퇴 신청은 번복 될 수 없습니다.',
    UNSUBSCRIBE_REASON: '탈퇴 이유',
    UNSUBSCRIBE_REASON_PLACEHOLDER: '(기타 선택) 자세한 이유를 입력해주세요.',
    UNSUBSCRIBE_REASON_DUPLICATE_ACCOUNT: '중복된 계정이 있습니다.',
    UNSUBSCRIBE_REASON_EARNING_NOT_GOOD: '수익이 만족스럽지 않습니다.',
    UNSUBSCRIBE_REASON_STOPPED_BUSINESS: '사업을 중단했습니다.',
    UNSUBSCRIBE_REASON_OTHERS: '기타',
    UNSUBSCRIBE_PAYOUT: '잔여 수익금',
    UNSUBSCRIBE_PAYOUT_MESSAGE: '잔여 수익금을 지급 받으시겠습니까?',
    UNSUBSCRIBE_USER_MENU: '회원탈퇴',
    UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_1: '최종 승인 전 탈퇴를 진행 할 경우, 잔여 수익금을 받을 수 없습니다.',
    UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_2: '소명되지 않은 부정행위로 인한 수익금은 탈퇴시 지급 받을수 없습니다.',
    UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_3: 'GMV generated, but RRN/Bank Account is not provided. You have to give up the commission. ',
    UNSUBSCRIBE_GIVE_UP_PAYOUT_TIPS: '개인 및 개인사업자 회원의 경우, 매월 말일까지 탈퇴가 완료되어야만 수익금이 지급되지 않습니다. 기준일자 이후에 탈퇴하는 경우 이를 포기하더라도 일부 수익금이 지급될 수 있습니다. <br/> (예시: 6월을 기준으로, 6월 30일까지 탈퇴가 완료되어야만 5월 1일~ 5월 31일까지 발생한 수익금(6월 25일 정산)이 지급일(7월 15일)에 지급되지 않으며, 6월 30일 이후에 탈퇴하는 경우에는 7월 15일에 정산된 수익금이 지급됩니다.)',
    DEACTIVATE: '탈퇴',

    WIDGET_LIST_SUB_TITLE: '선택한 카테고리의 베스트 상품이 움직이는 이미지로 제공됩니다.',
    WIDGET_LIST_CREATE: '배너 생성​',
    WIDGET_LIST_COL_NAME: '배너 제목',
    WIDGET_LIST_COL_TYPE: '배너 타입',
    WIDGET_LIST_COL_DATASET_NAME: '배너 데이터',
    CREATED_DATE: '생성일​',
    LAST_MODIFIED: '수정일​',
    DEL_CONFIRM_TITLE: '확인하다?',
    DEL_CONFIRM_DESCRIPTION: '{name}을 정말 삭제하시겠습니까?​',

    WIDGET_LIST_COL_ACTIONS: '복사/수정/삭제​',
    WIDGET_LIST_DEL_CONFIRM_TITLE: '배너 삭제​',
    WIDGET_LIST_DEL_CONFIRM_DESCRIPTION: '{name}을 정말 삭제하시겠습니까?​',

    WIDGET_INST_TITLE: '배너 만들기​',
    WIDGET_INST_PREVIEW: '미리보기​',
    WIDGET_INST_SETTINGS: '배너 설정',
    WIDGET_INST_NAME: '배너 제목',
    WIDGET_INST_DATA_TYPE: '배너 타입',
    WIDGET_INST_DATA_SET: '배너 데이터',
    WIDGET_INST_PARAMETERS: '크기 조정​',
    WIDGET_INST_PARAM_WIDTH: '너비​',
    WIDGET_INST_PARAM_HEIGHT: '높이​',
    WIDGET_INST_PARAM_BORDER: '테두리​',
    WIDGET_INST_WITH_BORDER: '테두리 있음',
    WIDGET_INST_WITHOUT_BORDER: '테두리 없음',
    WIDGET_INST_SIZE_VALIDATION_MSG: '50~2000 사이의 값을 입력해 주세요', //'80~2000 픽셀 사이의 값을 입력해 주세요',
    WIDGET_INST_CLOSE: '취소​',
    WIDGET_INST_GENERATE: '배너 만들기​',
    WIDGET_INST_CLOSE_CONFIRM_TITLE: '배너 만들기를 취소 하시겠습니까?​',
    WIDGET_INST_CLOSE_CONFIRM_DESCRIPTION: '확인 버튼을 누르면 배너 만들기가 취소됩니다.​',
    WIDGET_INST_CODE: '파트너스 코드',
    WIDGET_INST_DATASET_MANAGEMENT: '배너 데이터 상품 선택',
    WIDGET_INST_DATASET_NAME: '배너 데이터 이름',
    WIDGET_INST_DATASET_DELETE_CONFIRM_TITLE: '삭제 확인',
    WIDGET_INST_DATASET_DELETE_CONFIRM: '정말로 [{name}] 데이터를 삭제 하시겠습니까?',

    OPEN_API_BANNER_TIP: '쿠팡 파트너스 API는 사용자의 수익 활동에 도움이 되는 쿠팡 파트너스의 다양한 서비스를 인터페이스 형식으로 제공합니다. 이를 통해 사용자의 웹 사이트나 어플리케이션에 적용 할 수 있는 쿠팡 상품 정보를 포함한 파트너스의 기능과 및 실적 정보 등을 제공합니다.',
    OPEN_API_BANNER_HELP_LINK: 'Open API와 관련된 자세한 정보는 링크를 확인하세요.',
    OPEN_API_INACTIVE_TITLE: 'API 키 발급',
    OPEN_API_INACTIVE_MSG: "아래 ‘생성’ 버튼을 눌러 API 키를 발급 받으세요. API키는 최종 승인된 회원만 발급이 가능합니다.",
    OPEN_API_ACTIVE_TITLE: 'API 키 발급',
    OPEN_API_ACTIVE_MSG: '아래의 Access Key와 Secret Key는 인증된 사용자임을 입증하는 고유 텍스트 문자열이며, 이를 사용하여 파트너스 API를 호출 할 수 있습니다.<br />보안상의 문제로 Access Key와 Secret Key가 <span class="color-danger ft-bold-500">절대</span> 노출되지 않도록 유의해 주시기 바랍니다.',
    OPEN_API_BLOCKED_TITLE: 'API 키 사용 중단',
    OPEN_API_BLOCKED_MSG: "API 키 사용이 중단되었습니다. 파트너스에 문의 바랍니다.",
    OPEN_API_DELETED_TITLE: 'API 키 삭제',
    OPEN_API_DELETED_MSG: "API 키가 삭제되었습니다. 파트너스에 문의 바랍니다.",
    OPEN_API_BTN_GENERATE: '생성',

    CHROME_EXT_INSTALL_CHROME: '크롬 브라우저 설치하기',
    CHROME_EXT_INSTALL_EXENTION: '파트너스 확장 프로그램 설치하기',
    CHROME_EXT_DOWNLOAD_CHROME: 'Chrome 다운로드',
    CHROME_EXT_DOWNLOAD_EXTENSION: 'chrome 웹스토어',

    REACTIVATE_TITLE: '재가입을 계속 진행하시겠습니까?',
    REACTIVATE_CHECK_NOTICE: '재가입을 진행하기 전에 아래 사항을 확인해 주시기 바랍니다.',
    REACTIVATE_NOTICE1: '재가입은 탈퇴한 계정 복구가 아닌 신규 AF ID로 발급됩니다.',
    REACTIVATE_NOTICE2: '기존 계정의 탈퇴 이전 수익은 재가입한 계정으로 이관되나, 재가입 이후에는 탈퇴하신 계정의 정보를 확인할 수 없습니다.',
    REACTIVATE_NOTICE3: '기존 계정의 상품링크/배너/위젯/API는 재가입 계정으로 연결되지 않으며, 재가입 이후 신규 생성, 발급받아 사용해야 합니다.',

    SUB_ID_TITLE: '채널 아이디 관리',
    SUB_ID_SUB_TITLE: '채널 아이디를 관리 할 수 있습니다. 자세한 사항은 {help}을 참고하세요.',
    SUB_ID: '채널 아이디',
    SUB_ID_INPUT_PLACEHOLDER: '8 ~ 20 자 숫자 및 영어 대소문자',
    SUB_ID_EMPTY: '생성된 채널 아이디가 없습니다.',
    SUB_ID_DELETE_MODAL_TITLE: '채널 아이디 삭제',
    SUB_ID_DELETE_MODAL_CONTENT: '{subId}를 정말로 삭제 하시겠습니까?{br}삭제된 채널 아이디에 대한 정산 및 지급은 제공되지 않습니다.',
    SUB_ID_CREATE_MODAL_TITLE: '채널 아이디 생성',
    CREATE_METHOD: '생성 방식',

    SUB_ID_INVALID: '채널 아이디는 8 ~ 20자 숫자 및 영어 대소문자만 가능합니다.',
    SUB_ID_IS_EXISTED: '중복된 채널 아이디입니다.',
    SUB_ID_MAX_COUNT: '채널 아이디는 최대 {count}개까지만 제공됩니다.',

    SUB_ID_DEFAULT: '기본값',

    NOTIFICATION_TITLE: '알림',
    NOTIFICATION_EMPTY: '알림이 없습니다.',
    NOTIFICATION_VIEW_ALL: '더 보기',
    NOTIFICATION_MARK_ALL_AS_READ: '모두 읽음으로 표시',
    NOTIFICATION_MARK_AS_READ: '읽음으로 표시',
    NOTIFICATION_ANY_TYPE: '모든 타입',
    NOTIFICATION_TYPE_GENERAL: '일반',
    NOTIFICATION_TYPE_ALERT: '알림',
    NOTIFICATION_TYPE_PROMOTION: '프로모션',

    DOWNLOAD_REPORT_COL_ID: '리포트 ID',
    DOWNLOAD_REPORT_COL_TYPE: '유형',
    DOWNLOAD_REPORT_COL_PERIOD: '기간',
    DOWNLOAD_REPORT_COL_SUBIDS: '채널 아이디',
    DOWNLOAD_REPORT_COL_STATUS: '상태',
    DOWNLOAD_REPORT_COL_SIZE: '파일 크기',
    DOWNLOAD_REPORT_COL_CREATED_DATE: '생성 일자',
    DOWNLOAD_REPORT_JOB_TYPE_DAILY_TREND: '일별 실적',
    DOWNLOAD_REPORT_JOB_TYPE_AFFILIATE_DAILY_TREND: '일별 실적',
    DOWNLOAD_REPORT_JOB_TYPE_AFFILIATE_DYNAMIC_BANNER_DAILY_TREND: '다이나믹 배너​ 일별 실적',
    DOWNLOAD_REPORT_STATUS_INIT: '대기',
    DOWNLOAD_REPORT_STATUS_RUNNING: '실행 중',
    DOWNLOAD_REPORT_STATUS_SUCCEED: '완료',
    DOWNLOAD_REPORT_STATUS_FAILED: '실패',
    DOWNLOAD_REPORT_JOB_DELETE_CONFIRM: '[{id}] 번 리포트를 삭제하시겠습니까?',

    USER_STATUS_TIP_TEXT_APPROVED: '승인 완료',
    USER_STATUS_TIP_TEXT_REJECTED: '승인 반려',
    USER_STATUS_TIP_TEXT_PENDING: '승인 진행 중',
    USER_STATUS_TIP_TEXT_DEFAULT: '활동 중',

    ANNOUNCEMENT_CATEGORY: '말머리',

    CURRENCY_CODE_WON: '원',
    ENGLISH_AND_NUMBERS_ONLY: '영어와 숫자만 가능합니다.',
    VIEW_DETAIL: '내용보기',
    VIEW_MORE: '더보기',
    CLOSE: '닫기',
    PREVIOUS: '이전 단계',
    NEXT: '다음 단계',
    WIZARD_PREVIOUS: '이전',
    WIZARD_NEXT: '다음',
    DONE: '완',
    ADD: '추가',
    ADD_URL: '추가하기',
    OK: '승인',
    CANCEL: '취소',
    CONFIRM: '확인',
    CONTINUE: '계속',
    SAVE: '저장',
    CHANGE: '변화',
    APPROVE: '승인',
    REAPPLY: '다시 신청하기',
    VERIFY: '인증하기',
    LOGIN: '로그인',
    SIGNUP: '회원가입',
    SIGNEDUP: '가입완료',
    LOGOUT: '로그 아웃',
    MULTIPLE_SELECTION: '복수선택 가능',
    ALL: '전체',
    SHOPPING: '쇼핑하기',
    COPIED: '복사됐습니다!',
    COPIED_DISCLAIMER: '복사 완료! 게시글에 대가성 문구 기재를 잊지 마세요!',
    SEARCH_RESULTS: '개의 검색결과',
    ERROR: 'Error',
    SESSION_TIMEOUT_MESSAGE: '세션이 만료되었습니다. 다시 로그인해주세요.',
    ARE_YOU_SURE: '확실합니까?',
    NOTICE: '알림',
    COPY: '복사',
    ID: 'ID',
    LOOKUP: '조회',
    VIEW_DETAILS: '자세히',
    YES: '예',
    NO: '아니오',
    ACTIONS: '관리',
    DELETE: '삭제',
    DELETED: '삭제 여부',
    VIEW_MORE_DIMENSION: '목록 더 보기',
    VISIT_PAGE: '페이지 방문',
    HELP: '도움말',

    PAY_STATUS: '지급 상태',

    COUPANG_RANKING: '쿠팡랭킹순',
    COUPANG_RANKING_LOW_PRICE: '낮은가격순',
    COUPANG_RANKING_HIGH_PRICE: '높은가격순',
    COUPANG_RANKING_SALES_ORDER: '판매량순',
    COUPANG_RANKING_THE_LATEST: '최신순',

    VAL_MSG_THIS_FIELD_IS_REQUIRED: '이 항목은 필수값입니다.',
    VAL_MSG_FIELD_REQUIRED: '{name} 는(은) 필수 항목입니다.',
    VAL_MSG_ENTER_VALID_FIELD: '유효한 {name}를 입력해주세요',
    VAL_MSG_INVALID_FIELD: '유효하지 않은 {name}',
    VAL_MSG_FIELD_INVALID: '{name}가 올바르지 않습니다',

    MSG_INVALID_IMAGE_FILE: '유효하지 않은 이미지 파일',
    MSG_START_DATE_IS_REQUIRED: '시작일을 선택하십시오.',
    MSG_END_DATE_IS_REQUIRED: '종료일을 선택하십시오.',
    MSG_OPEN_API_KEYS_APPLIED_FAILED: 'API 키 생성에 실패하였습니다.',
    MSG_OPEN_API_KEYS_ALREADY_APPLIED: '이미 API 키를 생성하였습니다.',
    MSG_FAILED_TO_LOAD_DATA: '데이터를로드하지 못했습니다',

    PAYEE_NAME_MUST_MATCH_ACCOUNT_HOLDER: '지급받으실 분의 이름과 계좌주명이 일치해야 합니다',
    BANK_ACCOUNT_LENGTH_AT_LEAST: '계좌번호는 최소 {n}자리입니다',

    CHANGE_THE_UPLOADED_FILE: '파일 변경',
    CHANGE_THE_UPLOADED_DOC_CONFIRMATION: '이미 업로드된 문서가 있습니다. 변경하시겠습니까?',
    CHOOSE_A_IMAGE_FILE: '이미지 파일을 선택하십시오.',
    FILE_SIZE_IS_TOO_LARGE: '파일 크기는 5MB 이하여야 합니다',
    UPLOAD_COMPLETED: '업로드 완료되었습니다.',

    DO_YOU_WANT_TO_REGISTER_THE_DOCUMENTS: '서류를 등록하시겠습니까?​',

    CONSENT_WHEN_YOUR_CHANGE_SITES: '위 내용에 동의해 주세요',

    AUTH_FAILED: '인증 실패',
    PASSWORD_INCORRECT: '비밀번호가 맞지 않습니다',

    ONLY_PROVIDE_REPORTS_FOR_UP_TO_N_DAYS: '최대 {n} 일 동안의 리포트만 제공됩니다.',

    DOWNLOAD: '다운로드',
    START_DATE: '시작일',
    END_DATE: '종료일',
    DATE_RANGE: '날짜 범위',

    YESTERDAY: '어제',
    ONE_WEEK: '일주일',
    '30_DAYS': '30일',
    CUSTOMIZED: '맞춤형',

    NO_DATA: '데이터 없음',
    N_SELECTED: '{n}개 선택됨',
    METRIC: '측정값',

    VERSION: '버전',
    SELECT: '추가',
    DESCRIPTION: '설명',
    TITLE: '제목',
    AFFILIATE_FEE_NOTICE: `
<div style="color:#e56a2c">
게시글 작성 시, 아래 문구를 반드시 기재해 주세요. <br />
"이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다." <br /> <br />
</div>
쿠팡 파트너스의 활동은 공정거래위원회의 심사지침에 따라 추천, 보증인인 파트너스 회원과 당사의 경제적 이해관계에 대하여 공개하여야 합니다. <br />
자세한 내용은 "도움말>자주묻는질문>이용문의>광고 삽입 시 대가성 문구를 써야하나요?"(링크삽입)을 참고해 주세요.
    `,

    SEND: '보내기',
    JS_TAG: '자바스크립트 태그',
    IFRAME_TAG: 'iframe 태그',
    HTML_TAG: 'HTML 태그',

    DATA_RETENTION_FOR_180_DAYS: '리포트는 최대 180일 동안 저장됩니다.',

    PLEASE_AGREE_ALL: '필수 항목에 모두 동의해주세요',

    DOWNLOAD_PDF: 'PDF 내려받기',

    VIEW_DOCUMENT: '전문 보기',

    NAME: '이름',
    BIRTHDAY: '생년월일',
    NAME_CHANGE: '이름 변경',
    NAME_SYNC_HELP: '개명을 하신 경우 쿠팡의 본인인증 (쿠팡 > 마이쿠팡 > 회원정보 수정) 및 NICE 정보통신의 실명등록을 완료하신 후 쿠팡 파트너스 이름 변경이 가능합니다.',
    NAME_SYNC_SUCCESS: '이름 변경이 완료 되었습니다.',
    SENDER: '전송자',
    TIME: '일시',
    RESULTS: '결과',
    PASSBACK: '패스백 기능',

    IMPORTANT: '중요',

}
