import { combineReducers } from 'redux';
import { reducer as registrationReducer } from './registration';
import { reducer as paymentReducer } from './payment';
import { reducer as productDiscovery } from './productlink';
import { reducer as reportReducer } from './report';
import { reducer as dashboardReducer } from './dashboard';
import { reducer as settlementReducer } from './settlement';
import { reducer as affiliateReducer, subIdReducer, notificationReducer } from './affiliate';
import { reducer as authReducer } from './auth';

const configReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SWITCH_LANGUAGE':
            return {
                ...state,
                locale: action.payload,
            };
        default:
            break;
    }
    return state;
};

const sessionReducer = (state = { ...window.cpapp.session }, action) => {
    switch (action.type) {
        case 'SESSION_TIMEOUT':
            return {
                ...state,
                authenticated: false,
                cmd: action.cmd,
            };
        default:
            break;
    }
    return state;
};

const webAccessibleStatusReducer = (state = { ...window.cpapp.webAccessibleStatus }) => state;

export default () =>
    combineReducers({
        config: configReducer,
        session: sessionReducer,
        affiliate: affiliateReducer,
        webAccessibleStatus: webAccessibleStatusReducer,
        auth: authReducer,
        registration: registrationReducer,
        payment: paymentReducer,
        productlink: productDiscovery,
        dashboard: dashboardReducer,
        report: reportReducer,
        settlement: settlementReducer,
        subId: subIdReducer,
        notifications: notificationReducer,
    });
