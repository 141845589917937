import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Modal, Button } from 'antd';

export default function SessionTimeout() {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const loggedIn = useSelector((state) => state.session.authenticated);
    const cmd = useSelector((state) => state.session.cmd);
    const [visible, setVisible] = useState(true);

    const onConfirm = () => {
        setVisible(false);
        if (cmd === 'reload') {
            window.location.reload();
        } else {
            history.push({ pathname: '/', state: { from: location } });
        }
    };

    return (
        <Modal
            visible={visible && !loggedIn}
            closable={false}
            wrapClassName="modal auth-modal"
            width={440}
            zIndex={9999}
            title={null}
            footer={null}
        >
            <div className="modal-title large-title">에러</div>

            <div className="modal-body">{intl.formatMessage({ id: 'SESSION_TIMEOUT_MESSAGE' })}</div>
            <div className="modal-footer">
                <Button type="primary" size="large" onClick={onConfirm}>
                    {intl.formatMessage({ id: 'CONFIRM' })}
                </Button>
            </div>
        </Modal>
    );
}
